import * as Cesium from 'cesium';
import { toRaw } from 'vue';
import CesiumFunctions from '@/core/cesium/CesiumFunctions';

const initialState = {
  currentView: null,
  currentMode: null,
  currentTool: null,
  viewer: null,
  viewerCompare: null,
  activeShapePoints: [],
  activeShapePointsGeometry: [],
  activeShape: null,
  uncommitedShapes: [],
  floatingPoint: null,
  issuesBillboard: null,
  activeIssuesCategories: [],
  activeCameraEntity: null,
  lastHiddenCameraEntity: null,
  selectedMeasurementId: null,
  lastActiveIssueId: null,
  lastActiveIssueBillboard: null,
  savedCameraPosition: null,
  photosNearClick: null,
};

const state = { ...initialState };

const getters = {};

const actions = {
  showNotes({ state, rootState, commit }) {
    let notesEntity = state.viewer.entities.getById('notesEntity');
    if (notesEntity) {
      notesEntity.show = true;
      return;
    }
    notesEntity = state.viewer.entities.add({
      id: 'notesEntity',
      show: false,
    });
    rootState.inspections__new.getInspectionNotesResult.forEach(x => {
      commit('drawNote', x);
    });
    notesEntity.show = true;
  },
  hideNotes() {
    const notesEntity = state.viewer.entities.getById('notesEntity');
    if (!notesEntity) {
      return;
    }
    state.viewer.entities.getById('notesEntity').show = false;
  },
  showIssues({ state, rootState, commit }) {
    if (state.issuesBillboard) {
      state.issuesBillboard.show = true;
      return;
    }
    state.issuesBillboard = new Cesium.BillboardCollection();
    rootState.inspections__new.getInspectionIssuesResult.forEach(x => {
      commit('drawIssue', x);
    });
    state.viewer.scene.primitives.add(state.issuesBillboard);
  },
  filterIssues({ state, rootState, commit }) {
    const isIssuesBillboardCreated = !!state.issuesBillboard;
    if (isIssuesBillboardCreated) {
        state.issuesBillboard.removeAll();
    } else {
      state.issuesBillboard = new Cesium.BillboardCollection();
    }
    rootState.inspections__new.getInspectionIssuesResult.filter(x => state.activeIssuesCategories.length === 0 || state.activeIssuesCategories.indexOf(x.issue_type) > -1 ).forEach(x => {
      commit('drawIssue', x);
    });
    if (!isIssuesBillboardCreated) {
      state.viewer.scene.primitives.add(state.issuesBillboard);
    }
  },
  hideIssues() {
    if (state.issuesBillboard) {
      state.issuesBillboard.show = false;
    }
  },
  showCameras({ state, rootGetters, commit }) {
    let camerasEntity = state.viewer.entities.getById('camerasEntity');
    if (camerasEntity) {
      camerasEntity.show = true;
      return;
    }
    camerasEntity = state.viewer.entities.add({
      id: 'camerasEntity',
      show: false,
    });
    rootGetters['inspections__new/photosWithCesiumData'].forEach(x => {
      commit('drawCamera', x);
    });
    camerasEntity.show = true;
  },
  hideCameras() {
    const camerasEntity = state.viewer.entities.getById('camerasEntity');
    if (!camerasEntity) {
      return;
    }
    state.viewer.entities.getById('camerasEntity').show = false;
  },
  updateCameraMarkers({ commit }) {
    commit('showHiddenCamera');
    commit('hideActiveCamera');
  },
  flyToCamera({ state, dispatch, commit }, camera) {
    commit('saveCamera');
    const showHiddenCamera = function() {
      state.viewer.camera.moveStart.removeEventListener(showHiddenCamera);
      commit('showHiddenCamera');
    };
    state.activeCameraEntity = camera;
    let cameraOptions = {
      destination: camera.position._value,
      orientation: {
        heading: camera.heading,
        pitch: camera.pitch,
        roll: camera.roll
      },
      duration: 1.5,
      complete: () => {
        dispatch('updateCameraMarkers');
        state.viewer.camera.moveStart.addEventListener(showHiddenCamera);
      },
    };
    state.viewer.camera.flyTo(cameraOptions);
  },
  cameraFlyToIssue({ state, dispatch, commit, rootState, rootGetters }, payload) {
    commit('saveCamera');
    const issueHandler = (givenIssue = null) => {
      const validIssue = givenIssue || rootState.inspections__new.getInspectionIssuesResult.find(x => x.id === payload.id);
      if (validIssue) {
        const photoHandler = () => {
          const validPhoto = rootGetters['inspections__new/photosWithCesiumData'].find(x => x.id === validIssue.photo);
          if (validPhoto) {
            dispatch('cameraFlyTo', {
              type: 'heading-pitch-roll',
              position: validPhoto.position,
              data: validPhoto.hpr,
            });
            if (state.lastActiveIssueBillboard) {
              state.lastActiveIssueBillboard.color = new Cesium.Color(0, 1, 0, 0.5);
            }
            const billboard = state.issuesBillboard?._billboards?.find(x => x.id === 'issue-' + validIssue.id);
            if (billboard?.position) {
              state.lastActiveIssueBillboard = billboard;
              state.lastActiveIssueBillboard.color = Cesium.Color.BLUE;
            } else {
              state.lastActiveIssueBillboard = null;
              console.warn('no issue position found');
            }
          } else {
            console.warn('photo not found, abort');
            return;
          }
        };
        console.info('issue found, cheking photos', validIssue);
        if (!rootState.inspections__new.getInspectionPhotosResult ||
          rootState.inspections__new.getInspectionPhotosResult.length === 0 ||
          rootState.inspections__new.getInspectionPhotosResult[0].inspection !== rootState.inspections__new.currentInspection.id
        ) {
          console.info('need to load photos');
          dispatch('inspections__new/getInspectionPhotos', {
            id: rootState.inspections__new.currentInspection.id,
            onSuccess: photoHandler,
          }, { root: true });
        } else {
          console.info('checking loaded photos');
          photoHandler();
        }
      } else {
        console.warn('issue not found, abort');
        return;
      }
    }
    if (payload.issue) {
      issueHandler(payload.issue);
    } else if (!rootState.inspections__new.getInspectionIssuesResult ||
      rootState.inspections__new.getInspectionIssuesResult.length === 0 ||
      rootState.inspections__new.getInspectionIssuesResult[0].inspection !== rootState.inspections__new.currentInspection.id
    ) {
      console.warn('need to load issues');
      dispatch('inspections__new/getInspectionIssues', {
        id: rootState.inspections__new.currentInspection.id,
        onSuccess: issueHandler,
      }, { root: true });
    } else {
      console.warn('checking loaded issues');
      issueHandler();
    }
    return;
  },
  cameraFlyTo({ state }, payload) {
    let flyToOptions = {
      destination: payload.position,
      duration: 0.5,
    };
    if (payload.type === 'heading-pitch-roll' || payload.type === 'direction-up') {
      flyToOptions.orientation = payload.data;
    }
    state.viewer.camera.flyTo(flyToOptions);
  },
  completeToolAction({ state }) {
    if (!state.currentTool || !state.viewer) {
      return;
    }
    if (state.currentTool.code.indexOf('measure-') > -1) {
      if (Cesium.defined(state.floatingPoint)) {
        const points = state.activeShapePoints.slice(0, state.activeShapePoints.length - 1);
        const shape = CesiumFunctions.drawShape({ viewer: state.viewer, positions: points, type: state.currentTool.type });
        state.uncommitedShapes.unshift({
          id: shape.id,
          type: state.currentTool.type,
          positions: points,
        });
        state.viewer.entities.remove(state.floatingPoint);
        state.viewer.entities.remove(state.activeShape);
        state.activeShapePointsGeometry.forEach(x => {
          state.viewer.entities.remove(x);
        });
        state.floatingPoint = null;
        state.activeShape = null;
        state.activeShapePoints = [];
        state.activeShapePointsGeometry = [];
      }
    }
    if (state.currentTool.code === 'notes-add') {
      if (Cesium.defined(state.floatingPoint)) {
        state.viewer.entities.remove(state.floatingPoint);
        state.floatingPoint = null;
      }
    }
  },
  cancelToolAction({ state }) {
    if (!state.currentTool || !state.viewer) {
      return;
    }
    if (state.currentTool.code.indexOf('measure-') > -1) {
      if (Cesium.defined(state.floatingPoint)) {
        state.viewer.entities.remove(state.floatingPoint);
      }
      if (Cesium.defined(state.activeShape)) {
        state.viewer.entities.remove(state.activeShape);
      }
      state.floatingPoint = null;
      state.activeShape = null;
      state.activeShapePoints = [];
      state.activeShapePointsGeometry.forEach(x => {
        state.viewer.entities.remove(x);
      });
      state.activeShapePointsGeometry = [];
    }
  },
  toggleMeasurementItem({ state, commit }, { id }) {
    if (state.selectedMeasurementId === id) {
      commit('setSelectedMeasurementId', null);
    } else {
      const duration = 0.5; // seconds
      commit('setSelectedMeasurementId', id);
      const params = {
        entityId: id,
        options: { duration },
      };
      commit('flyToEntity', params);
    }
  }
};

const mutations = {
  reset(state) {
    Object.keys(state).forEach(x => {
      state[x] = initialState[x];
    })
  },
  drawIssue(state, payload) {
    if (!payload.model_intersection_center) {
      return;
    }
    const parsedPosition = JSON.parse(payload.model_intersection_center);
    if (!parsedPosition ||
        !Array.isArray(parsedPosition) ||
        parsedPosition.length !== 3) {
      return;
    }
    state.issuesBillboard.add({
      id: 'issue-' + payload.id,
      position: new Cesium.Cartesian3(...parsedPosition),
      image: 'media/icons/icon.png',
      translucencyByDistance: new Cesium.NearFarScalar(20, 0.8, 50, 0.5),
      scaleByDistance: new Cesium.NearFarScalar(1, 3, 50, 0.8),
      alignedAxis: Cesium.Cartesian3.UNIT_Z,
      scale: 0.7,
      show: true,
      color: new Cesium.Color(0, 1, 0, 0.5),
      eyeOffset: new Cesium.Cartesian3(0.0, 0.0, -1.0),
    });
  },
  drawNote(state, payload) {
    if (!payload.position) {
      return;
    }
    state.viewer.entities.add({
      id: 'note-' + payload.id,
      position: new Cesium.Cartesian3(...(payload.position.split(',').map(x => parseFloat(x)))),
      parent: state.viewer.entities.getById('notesEntity'),
      point: {
        color: new Cesium.Color(0.56, 0.79, 0.97, 1),
        pixelSize: 20,
      },
      label: {
        text: payload.id.toString(),
        font: '12pt sans-serif',
        style: Cesium.LabelStyle.FILL,
        outlineWidth: 2,
        showBackground: true,
        backgroundColor: new Cesium.Color(0.165, 0.165, 0.165, 0.8),
        backgroundPadding: new Cesium.Cartesian2(10, 10),
        eyeOffset: new Cesium.Cartesian3(0,0,-5),
        pixelOffset: new Cesium.Cartesian2(10, 10),
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
      }
    });
  },
  deleteNote(state, payload) {
    state.viewer.entities.remove({
      id: 'note-' + payload.id,
    });
  },
  drawCamera(state, payload) {
    state.viewer.entities.add({
      id: 'camera-' + payload.id,
      position: payload.position,
      parent: state.viewer.entities.getById('camerasEntity'),
      name : payload.id + ' // ' + payload.reference,
      description: 'point',
      isCamera: true,
      latitude: parseFloat(payload.latitude),
      longitude: parseFloat(payload.longitude),
      altitude: parseFloat(payload.altitude),
      heading: payload.hpr.heading + Cesium.Math.PI_OVER_TWO,
      pitch : payload.hpr.pitch,
      roll: payload.hpr.roll,
      hFOV: payload.hFOV,
      rotMatrix: payload.rotationMatrixAdj,
      orRotMatrix: payload.rotationMatrixAdj,
      range: 0.0,
      ellipsoid : {
        radii : new Cesium.Cartesian3(0.2, 0.2, 0.2),
        material : Cesium.Color.RED.withAlpha(0.3),
        outline : false,
        outlineColor : Cesium.Color.BLACK
      }
    });
  },
  showHiddenCamera(state) {
    if (state.lastHiddenCameraEntity) {
      state.lastHiddenCameraEntity.show = true;
      state.lastHiddenCameraEntity = null;
    }
  },
  hideActiveCamera(state) {
    if (state.activeCameraEntity) {
      state.activeCameraEntity.show = false;
      state.lastHiddenCameraEntity = state.activeCameraEntity;
    }
  },
  hideEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      entity.show = false;
    }
  },
  showEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      entity.show = true;
    }
  },
  removeEntity(state, entityId) {
    const entity = state.viewer.entities.getById(entityId);
    if (entity._children?.length > 0) {
      entity._children.forEach(x => {
        state.viewer.entities.remove(x);
      });
    }
    state.viewer.entities.remove(entity);
    state.uncommitedShapes = state.uncommitedShapes.filter(x => x.id !== entityId);
  },
  flyToEntity(state, { entityId, onComplete, options = {} } = {}) {
    const enrichedOptions = {
      duration: 0.5,
      ...options,
    };
    const entity = state.viewer.entities.getById(entityId);
    if (entity) {
      let promise;
      if (enrichedOptions.duration === 0) {
        promise = toRaw(state.viewer).zoomTo(toRaw(entity));
      } else {
        promise = toRaw(state.viewer).flyTo(toRaw(entity), enrichedOptions);
      }
      promise.then(() => {
        if (onComplete) {
          onComplete(entity);
        }
      });
    }
  },
  setSelectedMeasurementId(state, measurementId) {
    state.selectedMeasurementId = measurementId;
  },
  resetLastIssue(state) {
    state.lastActiveIssueId = null;
    if (state.lastActiveIssueBillboard) {
      state.lastActiveIssueBillboard.color = new Cesium.Color(0, 1, 0, 0.5);
    }
    state.lastActiveIssueBillboard = null;
  },
  saveCamera(state) {
    if (state.savedCameraPosition) {
      return;
    }
    state.savedCameraPosition = JSON.parse(JSON.stringify({
      type: 'heading-pitch-roll',
      position: state.viewer.camera.position,
      data: {
        heading: state.viewer.camera.heading,
        pitch: state.viewer.camera.pitch,
        roll: state.viewer.camera.roll
      },
    }));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
