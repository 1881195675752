import * as Cesium from 'cesium';
import CesiumFunctions from '@/core/cesium/CesiumFunctions';
import * as colors from '@/utils/colors';
import photos from './inspectionsPhotos';

const state = {
  currentInspection: null,

  getInspectionsResult: null,
  getInspectionsIsLoading: false,
  getInspectionsError: null,
  
  getInspectionsItemResult: null,
  getInspectionsItemIsLoading: false,
  getInspectionsItemError: null,

  addInspectionsItemResult: null,
  addInspectionsItemIsLoading: false,
  addInspectionsItemError: null,

  updateInspectionsItemResult: null,
  updateInspectionsItemIsLoading: false,
  updateInspectionsItemError: null,

  removeInspectionsItemResult: null,
  removeInspectionsItemIsLoading: false,
  removeInspectionsItemError: null,

  getInspectionNotesResult: null,
  getInspectionNotesIsLoading: false,
  getInspectionNotesError: null,

  createInspectionNotesItemResult: null,
  createInspectionNotesItemIsLoading: false,
  createInspectionNotesItemError: null,

  exportInspectionNotesResult: null,
  exportInspectionNotesIsLoading: false,
  exportInspectionNotesError: null,

  removeInspectionNotesItemResult: null,
  removeInspectionNotesItemIsLoading: false,
  removeInspectionNotesItemError: null,

  getInspectionIssuesResult: null,
  getInspectionIssuesIsLoading: false,
  getInspectionIssuesError: null,

  getInspectionPhotosResult: null,
  getInspectionPhotosIsLoading: false,
  getInspectionPhotosError: null,

  currentPhoto: null,

  getPhotosItemResult: null,
  getPhotosItemIsLoading: false,
  getPhotosItemError: null,

  getPhotoIssuesResult: null,
  getPhotoIssuesIsLoading: false,
  getPhotoIssuesError: null,

  addPhotoIssuesResult: null,
  addPhotoIssuesIsLoading: false,
  addPhotoIssuesError: null,

  updatePhotoIssuesResult: null,
  updatePhotoIssuesIsLoading: false,
  updatePhotoIssuesError: null,

  removePhotoIssuesResult: null,
  removePhotoIssuesIsLoading: false,
  removePhotoIssuesError: null,

  getPhotoNotesResult: null,
  getPhotoNotesIsLoading: false,
  getPhotoNotesError: null,

  addPhotoNotesResult: null,
  addPhotoNotesIsLoading: false,
  addPhotoNotesError: null,

  removePhotoNotesResult: null,
  removePhotoNotesIsLoading: false,
  removePhotoNotesError: null,
};

const getters = {
  noCesiumData(state) {
    return !(state.currentInspection && state.currentInspection.cesium_id);
  },
  photosWithCesiumData(state) {
    if (!state.getInspectionPhotosResult) {
      return null;
    }
    const result = state.getInspectionPhotosResult
      .filter(x => x.latitude && x.longitude && x.altitude && x.matrix)
      .map(x => {
        const photo = { ...x };
        let adjustmentsPos = null;
        if (state.getInspectionsItemResult.adjust_cameras) {
          adjustmentsPos = [
            parseFloat(state.getInspectionsItemResult.adjust_longitude),
            parseFloat(state.getInspectionsItemResult.adjust_latitude),
            parseFloat(state.getInspectionsItemResult.adjust_altitude),
          ];
        }
        // Positions
        let position = new Cesium.Cartesian3(parseFloat(photo.longitude), parseFloat(photo.latitude), parseFloat(photo.altitude));
        if (adjustmentsPos && !isNaN(adjustmentsPos[2])) {
          const position_carto = new Cesium.Cartographic.fromCartesian(position);
          position = new Cesium.Cartesian3.fromRadians(position_carto.longitude, position_carto.latitude, position_carto.height + adjustmentsPos[2]);
        }
        photo.position = position;

        // Rotations
        const rotations = JSON.parse(photo.matrix);
        // Create rotation matrix
        var rotationMatrix = new Cesium.Matrix3(rotations[0], rotations[1], rotations[2], rotations[3], rotations[4], rotations[5], rotations[6], rotations[7], rotations[8]);

        // Adjust for 90 degrees pitch
        var adjustment = Cesium.Matrix3.fromRotationY(Cesium.Math.toRadians(90.0));
        var rotationMatrixAdj = new Cesium.Matrix3();
        Cesium.Matrix3.multiply(rotationMatrix, adjustment, rotationMatrixAdj);

        photo.rotationMatrix = rotationMatrix;
        photo.rotationMatrixAdj = rotationMatrixAdj;

        // Calculate HPR for camera movements
        photo.transform = new Cesium.Matrix4.fromRotationTranslation(rotationMatrixAdj, position);
        photo.hpr = new Cesium.Transforms.fixedFrameToHeadingPitchRoll(photo.transform);
        photo.hpr.heading += Cesium.Math.PI_OVER_TWO;

        // Field of view from image size, sensor size and focal length
        var [hFOV, vFOV] = CesiumFunctions.calculateFOV(
          parseFloat(photo.image_width),
          parseFloat(photo.image_height),
          parseFloat(photo.sensor_size),
          parseFloat(photo.focal_length)
        );

        photo.hFOV = hFOV;
        photo.vFOV = vFOV;

        var [mL2, tL2, tR2, bR2, bL2] = CesiumFunctions.createFrustumMatrix(position, rotationMatrixAdj, hFOV, vFOV, 10);

        photo.frustum = { mL2, tL2, tR2, bR2, bL2 };

        return photo;
      });
    return result;
  },
  relatedInspections(state) {
    if (!state.getInspectionsResult || !state.currentInspection) {
      return [];
    }
    return state.getInspectionsResult.filter(x =>
      x.asset === state.currentInspection.asset && 
      !!x.cesium_id &&
      x.id !== state.currentInspection.id
    );
  },
};

const actions = {
  async getInspections ({ state, rootState }) {
    state.getInspectionsIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/inspections/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionsResult = data;
      state.getInspectionsError = null;
    } else {
      state.getInspectionsResult = null;
      state.getInspectionsError = 'error';
    }
    state.getInspectionsIsLoading = false;
  },
  async getInspectionsItem ({ state, rootState }, payload) {
    state.getInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionsItemResult = data;
      state.getInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionsItemResult = null;
      state.getInspectionsItemError = 'error';
    }
    state.getInspectionsItemIsLoading = false;
  },
  async addInspectionsItem ({ state, rootState }, payload) {
    state.addInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.addInspectionsItemResult = data;
      state.addInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addInspectionsItemResult = null;
      state.addInspectionsItemError = 'error';
    }
    state.addInspectionsItemIsLoading = false;
  },
  async updateInspectionsItem ({ state, rootState }, payload) {
    state.updateInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
        'Content-type': 'application/json',
      },
      body: payload.data,
    })
    let data = await res.json();
    if (res.ok) {
      state.updateInspectionsItemResult = data;
      state.updateInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.updateInspectionsItemResult = null;
      state.updateInspectionsItemError = 'error';
    }
    state.updateInspectionsItemIsLoading = false;
  },
  async removeInspectionsItem ({ state, rootState }, payload) {
    state.removeInspectionsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/inspections/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeInspectionsItemResult = 'success';
      state.removeInspectionsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeInspectionsItemResult = null;
      state.removeInspectionsItemError = 'error';
    }
    state.removeInspectionsItemIsLoading = false;
  },
  async getInspectionNotes ({ state, rootState }, payload) {
    if (!payload.isBackground) {
      state.getInspectionNotesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/annotations/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getInspectionNotesResult = data;
      state.getInspectionNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionNotesResult = null;
      state.getInspectionNotesError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionNotesIsLoading = false;
    }
  },
  async exportInspectionNotes ({ state, rootState }, payload) {
    state.exportInspectionNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/inspections/${payload.id}/export/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.exportInspectionNotesResult = data;
      state.exportInspectionNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.exportInspectionNotesResult = null;
      state.exportInspectionNotesError = 'error';
    }
    state.exportInspectionNotesIsLoading = false;
  },
  async createInspectionNotesItem ({ state, rootState }, payload) {
    state.createInspectionNotesItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload.data,
    })
    const data = await res.json();
    if (res.ok) {
      state.createInspectionNotesItemResult = data;
      state.createInspectionNotesItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.createInspectionNotesItemResult = null;
      state.createInspectionNotesItemError = 'error';
    }
    state.createInspectionNotesItemIsLoading = false;
  },
  async removeInspectionNotesItem ({ state, rootState }, payload) {
    state.removeInspectionNotesItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeInspectionNotesItemResult = 'success';
      state.removeInspectionNotesItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeInspectionNotesItemResult = null;
      state.removeInspectionNotesItemError = 'error';
    }
    state.removeInspectionNotesItemIsLoading = false;
  },
  async getInspectionIssues ({ state, rootState }, payload) {
    if (!payload.isBackground) {
      state.getInspectionIssuesIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/issues/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      data.forEach(x => {
        x.label_color_code = colors.issuesColors[x.issue_type.toLowerCase()] || colors.stringToColor((x.issue_type.length < 6 ? new Array(6).fill(x.issue_type).join('') : x.issue_type).toLowerCase());
        x.is_label_color_light = colors.isColorLight(x.label_color_code);
      });
      state.getInspectionIssuesResult = data;
      state.getInspectionIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionIssuesResult = null;
      state.getInspectionIssuesError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionIssuesIsLoading = false;
    }
  },
  async getInspectionPhotos ({ state, rootState }, payload) {
    if (!payload.isBackground) {
      state.getInspectionPhotosIsLoading = true;
    }
    const res = await fetch(`https://app.cursoram.com/api/photos/?inspection=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getInspectionPhotosResult = data;
      state.getInspectionPhotosError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getInspectionPhotosResult = null;
      state.getInspectionPhotosError = 'error';
    }
    if (!payload.isBackground) {
      state.getInspectionPhotosIsLoading = false;
    }
  },
  async getPhotosItem ({ state, rootState }, payload) {
    state.getPhotosItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/photos/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      data.altitude = parseFloat(data.altitude);
      data.latitude = parseFloat(data.latitude);
      data.longitude = parseFloat(data.longitude);
      state.getPhotosItemResult = data;
      state.getPhotosItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotosItemResult = null;
      state.getPhotosItemError = 'error';
    }
    state.getPhotosItemIsLoading = false;
  },
  async getPhotoIssues ({ state, rootState }, payload) {
    state.getPhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/?photo=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      data.forEach(x => {
        x.label_color_code = colors.issuesColors[x.issue_type.toLowerCase()] || colors.stringToColor((x.issue_type.length < 6 ? new Array(6).fill(x.issue_type).join('') : x.issue_type).toLowerCase());
        x.is_label_color_light = colors.isColorLight(x.label_color_code);
        try {
          x.vertices = x.vertices ? JSON.parse(x.vertices) : null;
        } catch (error) {
          x.vertices = null;
        }
        try {
          x.vertices_center = x.vertices_center ? JSON.parse(x.vertices_center) : null;
        } catch (error) {
          x.vertices_center = null;
        }
      });
      state.getPhotoIssuesResult = data;
      state.getPhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotoIssuesResult = null;
      state.getPhotoIssuesError = 'error';
    }
    state.getPhotoIssuesIsLoading = false;
  },
  async addPhotoIssues ({ state, rootState }, payload) {
    state.addPhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      state.addPhotoIssuesResult = data;
      state.addPhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addPhotoIssuesResult = null;
      state.addPhotoIssuesError = 'error';
    }
    state.addPhotoIssuesIsLoading = false;
  },
  async updatePhotoIssues ({ state, rootState }, payload) {
    state.updatePhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/${payload.get('id')}/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      try {
        data.vertices = x.vertices ? JSON.parse(x.vertices) : null;
        data.vertices_center = x.vertices_center ? JSON.parse(x.vertices_center) : null;
      } catch (error) {
        data.vertices = null;
        data.vertices_center = null;
      }
      state.updatePhotoIssuesResult = data;
      state.updatePhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.updatePhotoIssuesResult = null;
      state.updatePhotoIssuesError = 'error';
    }
    state.updatePhotoIssuesIsLoading = false;
  },
  async removePhotoIssues ({ state, rootState }, payload) {
    state.removePhotoIssuesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/issues/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removePhotoIssuesResult = 'success';
      state.removePhotoIssuesError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removePhotoIssuesResult = null;
      state.removePhotoIssuesError = 'error';
    }
    state.removePhotoIssuesIsLoading = false;
  },
  async getPhotoNotes ({ state, rootState }, payload) {
    state.getPhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/?photo=${payload.id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    let data = await res.json();
    if (res.ok) {
      state.getPhotoNotesResult = data;
      state.getPhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getPhotoNotesResult = null;
      state.getPhotoNotesError = 'error';
    }
    state.getPhotoNotesIsLoading = false;
  },
  async addPhotoNotes ({ state, rootState }, payload) {
    state.addPhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload,
    })
    let data = await res.json();
    if (res.ok) {
      state.addPhotoNotesResult = data;
      state.addPhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.addPhotoNotesResult = null;
      state.addPhotoNotesError = 'error';
    }
    state.addPhotoNotesIsLoading = false;
  },
  async removePhotoNotes ({ state, rootState }, payload) {
    state.removePhotoNotesIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/annotations/${payload.id}/`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removePhotoNotesResult = 'success';
      state.removePhotoNotesError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removePhotoNotesResult = null;
      state.removePhotoNotesError = 'error';
    }
    state.removePhotoNotesIsLoading = false;
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: { photos },
};
