const state = {
  defaultTagId: null,

  getMeasurementsResult: null,
  getMeasurementsIsLoading: false,
  getMeasurementsError: null,

  addMeasurementsItemResult: null,
  addMeasurementsItemIsLoading: false,
  addMeasurementsItemError: null,

  removeMeasurementsItemResult: null,
  removeMeasurementsItemIsLoading: false,
  removeMeasurementsItemError: null,
};

const getters = {
};

const actions = {
  async getMeasurements ({ state, rootState }, payload = {}) {
    state.getMeasurementsIsLoading = !payload.isBackground;
    let queries = [];
    if (payload.inspection) {
      queries.push(`inspection=${payload.inspection}`);
    }
    queries = queries.length > 0 ? '?' + queries.join('&') : '';
    const res = await fetch('https://app.cursoram.com/api/measurements/' + queries, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getMeasurementsResult = data.map(x => ({
        ...x,
        id: 'measure-' + x.id,
        type: x.shape,
        positions: JSON.parse(x.positions.replaceAll("'", '"')),
      }));
      state.getMeasurementsError = null;
    } else {
      state.getMeasurementsResult = null;
      state.getMeasurementsError = 'error';
    }
    state.getMeasurementsIsLoading = false;
  },
  async addMeasurementsItem ({ state, rootState }, payload = {}) {
    state.addMeasurementsItemIsLoading = !payload.isBackground;
    const res = await fetch('https://app.cursoram.com/api/measurements/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: payload.data,
    })
    const data = await res.json();
    if (res.ok) {
      state.addMeasurementsItemResult = data;
      state.addMeasurementsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.addMeasurementsItemResult = null;
      state.addMeasurementsItemError = 'error';
    }
    state.addMeasurementsItemIsLoading = false;
  },
  async removeMeasurementsItem ({ state, rootState }, payload = {}) {
    state.removeMeasurementsItemIsLoading = !payload.isBackground;
    const res = await fetch('https://app.cursoram.com/api/measurements/' + payload.id.replace('measure-', '') + '/', {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    if (res.ok) {
      state.removeMeasurementsItemResult = 'success';
      state.removeMeasurementsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    } else {
      state.removeMeasurementsItemResult = null;
      state.removeMeasurementsItemError = 'error';
    }
    state.removeMeasurementsItemIsLoading = false;
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
