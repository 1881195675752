import * as math from "mathjs"
import * as Cesium from 'cesium';
import { v4 as uuidv4 } from 'uuid';
import { toRaw } from 'vue';


function errorCamera(ray, viewer) {

  // Check difference in angle from current view to point and camera origin to point
  var rayCamera = viewer.camera.getPickRay(new Cesium.Cartesian2(
    Math.round(viewer.scene.canvas.clientWidth / 2),
    Math.round(viewer.scene.canvas.clientHeight / 2)
  ));

  // Log difference, which can be interpreted as the error
  var error = new Cesium.Cartesian3();
  Cesium.Cartesian3.subtract(ray.direction, rayCamera.direction, error);
  var error_calc = Math.abs(error.x) + Math.abs(error.y) + Math.abs(error.y);

  return (error_calc);
}

function checkImage({ clickPosition, photoPosition, frustum, viewer }) {

  var clicked = toRaw(clickPosition);
  var origin = toRaw(photoPosition);
  var frustum = toRaw(frustum);
  var viewer = toRaw(viewer);

  var distanceClickedOrigin = Cesium.Cartesian3.distance(clicked, origin);
  if (distanceClickedOrigin < 70.0) {

    // Create ray from clicked point to camera origin of picture
    var direction = new Cesium.Cartesian3();
    Cesium.Cartesian3.subtract(clicked, origin, direction);
    Cesium.Cartesian3.normalize(direction, direction);
    var ray = new Cesium.Ray(origin, direction);

    // Check if ray intersects with the two triangles that make up the frustum
    var intersection1 = new Cesium.IntersectionTests.rayTriangle(ray, frustum[0], frustum[1], frustum[2]);
    var intersection2 = new Cesium.IntersectionTests.rayTriangle(ray, frustum[1], frustum[2], frustum[3]);

    // If there are intersections, return the photo_id with error value
    if (typeof intersection1.x === 'undefined' && typeof intersection2.x === 'undefined') {
      return null;
    }
    var rayFirst = viewer.scene.pickFromRay(ray, []);
    var distanceError = rayFirst ? Cesium.Cartesian3.distance(clicked, rayFirst.position) : null;
    if (distanceError && distanceError >= 1) {
      return null;
    }
    var error = errorCamera(ray, viewer);
    // if (typeof intersection1.x !== 'undefined') {
    //   var point = intersection1;
    //   var substract0 = new Cesium.Cartesian3();
    //   var substract1 = new Cesium.Cartesian3();
    //   var substract2 = new Cesium.Cartesian3();
    //   var substractP = new Cesium.Cartesian3();
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[0], substract0)
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[1], substract1)
    //   Cesium.Cartesian3.subtract(frustum[0], frustum[2], substract2)
    //   Cesium.Cartesian3.subtract(frustum[0], point, substractP)
    //   var angle = Cesium.Cartesian3.angleBetween(substract1, substractP)
    //   var long = Cesium.Cartesian3.distance(frustum[0], frustum[1]);
    //   var short = Cesium.Cartesian3.distance(frustum[1], frustum[2]);
    //   var diagonal = Cesium.Cartesian3.distance(frustum[1], point);
    //   var adjacent = math.cos(angle) * diagonal;
    //   var pointX = adjacent / long;
    //   var oppposite = math.tan(angle) * adjacent;
    //   var pointY = oppposite / short;
    // }
    // else if (typeof intersection2.x !== 'undefined') {
    //   var point = intersection2;
    //   var substract1 = new Cesium.Cartesian3();
    //   var substract2 = new Cesium.Cartesian3();
    //   var substract3 = new Cesium.Cartesian3();
    //   var substractP = new Cesium.Cartesian3();
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[1], substract1)
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[2], substract2)
    //   Cesium.Cartesian3.subtract(frustum[3], frustum[3], substract3)
    //   Cesium.Cartesian3.subtract(frustum[3], point, substractP)
    //   var angle = Cesium.Cartesian3.angleBetween(substract2, substractP)
    //   var long = Cesium.Cartesian3.distance(frustum[3], frustum[2]);
    //   var short = Cesium.Cartesian3.distance(frustum[3], frustum[1]);
    //   var diagonal = Cesium.Cartesian3.distance(frustum[3], point);
    //   var adjacent = math.cos(angle) * diagonal;
    //   var pointX = (long - adjacent) / long;
    //   var oppposite = math.tan(angle) * adjacent;
    //   var pointY = (short - oppposite) / short;
    // }
    return error;
  }
}

function calculateFOV(imageWidth, imageHeight, sensorWidth, focalLength) {

  var sensorHeight = sensorWidth / imageWidth * imageHeight;
  var hFOV = 2 * math.atan(sensorWidth / (2 * focalLength));
  var vFOV = 2 * math.atan(sensorHeight / (2 * focalLength));

  return [hFOV, vFOV]
}

function offsetPoint(position, rotationMatrix, offset) {

  var offsetVector = new Cesium.Cartesian3(offset[0], offset[1], offset[2]);
  offsetVector = Cesium.Matrix3.multiplyByVector(rotationMatrix, offsetVector, offsetVector);

  var point = position.clone();
  Cesium.Cartesian3.add(point, offsetVector, point);

  return point;
}

function createFrustumMatrix(position, rotationMatrix, hFOV, vFOV, distanceFrustum) {

  var fromMiddleH = math.tan(hFOV / 2) * distanceFrustum;
  var fromMiddleV = math.tan(vFOV / 2) * distanceFrustum;

  var mL = offsetPoint(position, rotationMatrix, [distanceFrustum, 0, 0]);
  var tL = offsetPoint(position, rotationMatrix, [distanceFrustum, -fromMiddleH, fromMiddleV]);
  var tR = offsetPoint(position, rotationMatrix, [distanceFrustum, fromMiddleH, fromMiddleV]);
  var bR = offsetPoint(position, rotationMatrix, [distanceFrustum, fromMiddleH, -fromMiddleV]);
  var bL = offsetPoint(position, rotationMatrix, [distanceFrustum, -fromMiddleH, -fromMiddleV]);

  return [mL, tL, tR, bR, bL];
}

function detPolygon(a) {
  return a[0][0] * a[1][1] * a[2][2] + a[0][1] * a[1][2] * a[2][0] + a[0][2] * a[1][0] * a[2][1] - a[0][2] * a[1][1] * a[2][0] - a[0][1] * a[1][0] * a[2][2] - a[0][0] * a[1][2] * a[2][1];
}

function unitPolygon(a, b, c) {

  var x = detPolygon([[1, a[1], a[2]], [1, b[1], b[2]], [1, c[1], c[2]]]);
  var y = detPolygon([[a[0], 1, a[2]], [b[0], 1, b[2]], [c[0], 1, c[2]]]);
  var z = detPolygon([[a[0], a[1], 1], [b[0], b[1], 1], [c[0], c[1], 1]]);

  var magnitude = (x ** 2 + y ** 2 + z ** 2) ** 0.5

  return [x / magnitude, y / magnitude, z / magnitude];
}

function dotPolygon(a, b) {
  return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

function crossPolygon(a, b) {

  var x = a[1] * b[2] - a[2] * b[1];
  var y = a[2] * b[0] - a[0] * b[2];
  var z = a[0] * b[1] - a[1] * b[0];

  return [x, y, z];
}

function areaPolygon(poly) {

  if (poly.length < 3) {
    return 0;
  }

  var total = [0, 0, 0];
  for (var i = 0; i < poly.length; i++) {
    var vi1 = poly[i];
    if (i == poly.length - 1) {
      var vi2 = poly[0];
    }
    else {
      var vi2 = poly[i + 1];
    }
    var prod = crossPolygon(vi1, vi2);
    total[0] += prod[0];
    total[1] += prod[1];
    total[2] += prod[2];
  }

  var result = dotPolygon(total, unitPolygon(poly[0], poly[1], poly[2]))
  return Math.abs(result / 2);
}


function calculatePolygon(positionData) {

  let poly = [];
  for (let i = 0; i < positionData.length; i++) {
    const x = positionData[i].x;
    const y = positionData[i].y;
    const z = positionData[i].z;
    poly.push([x, y, z])
  }

  let result = areaPolygon(poly);
  if (result > 1) {
    result = Math.round(result * 100) / 100;
    result += 'm2';
  }
  else {
    result = result * 10000;
    result = Math.round(result * 100) / 100;
    result += 'cm2';
  }

  return result;
}

function calculateLine({ positions = [], isFormatted = true, isFlat = false } = {}) {
  let result = 0
  for (let i = 1; i < positions.length; i++) {
    const distance = Cesium.Cartesian3.distance(positions[i - 1], positions[i]);
    if (isFlat) {
      const point1AsCartographic = new Cesium.Cartographic.fromCartesian(positions[i - 1]);
      const point2AsCartographic = new Cesium.Cartographic.fromCartesian(positions[i]);
      const heightDiff = Math.abs(point1AsCartographic.height - point2AsCartographic.height);
      const flatDistance = Math.sqrt(distance ** 2 - heightDiff ** 2);
      result += flatDistance;
    } else {
      result += distance;
    }
  }

  if (!isFormatted) {
    return result;
  }

  if (result > 1) {
    result = Math.round(result * 100) / 100;
    result += 'm';
  }
  else {
    result = result * 100;
    result = Math.round(result * 100) / 100;
    result += 'cm';
  }

  return result;
}

function calculateHeight(positionData) {
  if (positionData.length < 2) {
    return '0m';
  }
  // let result = Cesium.Cartesian3.distance(new Cesium.Cartesian3(0.0, 0.0, positionData[0].z), new Cesium.Cartesian3(0.0, 0.0, positionData[1].z));
  // console.log(positionData[1].z, positionData[0].z, result, Math.abs(positionData[0].z - positionData[1].z));
  let result = Math.abs(positionData[0].z - positionData[1].z);

  if (result > 1) {
    result = Math.round(result * 100) / 100;
    result += 'm';
  }
  else {
    result = result * 100;
    result = Math.round(result * 100) / 100;
    result += 'cm';
  }

  return result;
}


function drawPoint({ viewer, position, parent }, isTest) {
  return viewer.entities.add({
    position,
    ellipsoid: {
      parent,
      radii: new Cesium.Cartesian3(0.05, 0.05, 0.05),
      material: isTest ? Cesium.Color.RED : Cesium.Color.LIGHTSKYBLUE,
    },
  });
}


function drawShape({ viewer, positions, type = 'line', id = 'measure-' + uuidv4(), isDraft = false } = {}) {
  let shape;
  let measurementsEntity = viewer.entities.getById('measurementsEntity');
  if (!measurementsEntity) {
    measurementsEntity = viewer.entities.add({
      id: 'measurementsEntity',
    });
  }
  if (isDraft) {
    let draftEntity = viewer.entities.getById('draftEntity');
    if (!draftEntity) {
      draftEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'draftEntity',
      });
    }
    if (type === 'polygon') {
      shape = viewer.entities.add({
        id,
        parent: draftEntity,
        position: positions[0],
        polygon: {
          hierarchy: positions,
          material: new Cesium.ColorMaterialProperty(
            Cesium.Color.LIGHTSKYBLUE.withAlpha(0.8)
          ),
          perPositionHeight: true,
        },
      });
    } else {
      shape = viewer.entities.add({
        parent: draftEntity,
        id,
        polyline: {
          positions: positions,
          width: 1,
          material: Cesium.Color.LIGHTSKYBLUE,
        },
      });
    }
  } else if (type === 'line') {
    let linesEntity = viewer.entities.getById('linesEntity');
    if (!linesEntity) {
      linesEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'linesEntity',
      });
    }
    const value = calculateLine({ positions });
    shape = viewer.entities.add({
      id,
      parent: linesEntity,
      position: positions[0],
      polyline: {
        positions,
        //clampToGround: true,
        width: 3,
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
      },
      label: {
        text: 'Length: ' + value,
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
  } else if (type === 'polygon') {
    let polygonsEntity = viewer.entities.getById('polygonsEntity');
    if (!polygonsEntity) {
      polygonsEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'polygonsEntity',
      });
    }
    const value = calculatePolygon(positions);
    shape = viewer.entities.add({
      id,
      parent: polygonsEntity,
      position: positions[0],
      polygon: {
        hierarchy: positions,
        // hierarchy: positions.map(x => ({ ...x, z: x.z + 0.05 })),
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
        perPositionHeight: true,
      },
      label: {
        text: 'Area: ' + value,
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
  } else if (type === 'height') {
    let heightsEntity = viewer.entities.getById('heightsEntity');
    if (!heightsEntity) {
      heightsEntity = viewer.entities.add({
        parent: measurementsEntity,
        id: 'heightsEntity',
      });
    }
    const horizontalLength = calculateLine({ positions, isFormatted: false, isFlat: true });
    shape = viewer.entities.add({
      id,
      parent: heightsEntity,
      position: positions[0],
      polyline: {
        positions,
        width: 3,
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.WHITE.withAlpha(0.8)
        ),
      },
      label: {
        // text: 'Height: ' + value,
        text: 'Height',
        font: '20pt sans-serif',
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        outlineWidth: 3,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        show: true
      }
    });
    viewer.entities.add({
      id: shape.id + '-ground',
      parent: shape,
      position: positions[0],
      polyline: {
        positions,
        clampToGround: true,
        width: 3,
        material: new Cesium.StripeMaterialProperty({
          repeat: horizontalLength * 2,
          evenColor: Cesium.Color.WHITE.withAlpha(0.66),
          oddColor: Cesium.Color.BLACK.withAlpha(0.66),
          orientation: Cesium.StripeOrientation.VERTICAL,
        }),
      },
    });
  }
  return shape;
}


export default { checkImage, calculateFOV, createFrustumMatrix, drawPoint, calculateLine, calculatePolygon, drawShape };

