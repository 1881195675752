const state = {
  getOrganizationsResult: null,
  getOrganizationsIsLoading: false,
  getOrganizationsError: null,

  getOrganizationsItemResult: null,
  getOrganizationsItemIsLoading: false,
  getOrganizationsItemError: null,

  createOrganizationsItemResult: null,
  createOrganizationsItemIsLoading: false,
  createOrganizationsItemError: null,

  editOrganizationsItemResult: null,
  editOrganizationsItemIsLoading: false,
  editOrganizationsItemError: null,

  removeOrganizationsItemResult: null,
  removeOrganizationsItemIsLoading: false,
  removeOrganizationsItemError: null,
};

const getters = {
};

const actions = {
  async getOrganizations ({ state, rootState }, payload = {}) {
    state.getOrganizationsIsLoading = true;
    const res = await fetch('https://app.cursoram.com/api/organizations/', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getOrganizationsResult = data;
      state.getOrganizationsError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getOrganizationsResult = null;
      state.getOrganizationsError = 'error';
    }
    state.getOrganizationsIsLoading = false;
  },
  async getOrganizationsItem ({ state, rootState }, payload) {
    state.getOrganizationsItemIsLoading = true;
    const res = await fetch(`https://app.cursoram.com/api/organizations/${payload.id}/`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    })
    const data = await res.json();
    if (res.ok) {
      state.getOrganizationsItemResult = data;
      state.getOrganizationsItemError = null;
      if (payload.onSuccess) {
        payload.onSuccess(data);
      }
    } else {
      state.getOrganizationsItemResult = null;
      state.getOrganizationsItemError = 'error';
    }
    state.getOrganizationsItemIsLoading = false;
  },
  async createOrganizationsItem ({ state, rootState }, payload) {
    state.createOrganizationsItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/organizations/', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.ok) {
      state.createOrganizationsItemResult = data;
      state.createOrganizationsItemError = null;
    } else {
      state.createOrganizationsItemResult = null;
      state.createOrganizationsItemError = 'error';
    }
    state.createOrganizationsItemIsLoading = false;
  },
  async editOrganizationsItem ({ state, rootState }, payload) {
    state.editOrganizationsItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/organizations/' + payload.id + '/', {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if (res.ok) {
      state.editOrganizationsItemResult = data;
      state.editOrganizationsItemError = null;
    } else {
      state.editOrganizationsItemResult = null;
      state.editOrganizationsItemError = 'error';
    }
    state.editOrganizationsItemIsLoading = false;
  },
  async removeOrganizationsItem ({ state, rootState }, payload) {
    state.removeOrganizationsItemIsLoading = true;
    let res = await fetch('https://app.cursoram.com/api/organizations/' + payload.id + '/', {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${rootState.accessToken}`,
      },
    });
    if (res.ok) {
      state.removeOrganizationsItemResult = 'success';
      state.removeOrganizationsItemError = null;
    } else {
      state.removeOrganizationsItemResult = null;
      state.removeOrganizationsItemError = 'error';
    }
    state.removeOrganizationsItemIsLoading = false;
  }
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
