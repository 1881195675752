export const baseColors = {
  blue: '0d6efd',
  indigo: '6610f2',
  purple: '6f42c1',
  pink: 'd63384',
  red: 'dc3545',
  orange: 'fd7e14',
  yellow: 'ffc107',
  green: '198754',
  teal: '20c997',
  cyan: '0dcaf0',
  white: 'fff',
  get primary() { return this.blue; },
  get secondary() { return this['gray-600']; },
  get success() { return this.green; },
  get info() { return this.cyan; },
  get warning() { return this.yellow; },
  get danger() { return this.red; },
  get light() { return this['gray-100']; },
  get dark() { return this['gray-900']; },
  'gray-100': 'f8f9fa',
  'gray-200': 'e9ecef',
  'gray-300': 'dee2e6',
  'gray-400': 'ced4da',
  'gray-500': 'adb5bd',
  'gray-600': '6c757d',
  'gray-700': '495057',
  'gray-800': '343a40',
  'gray-900': '212529',
};

export const issuesColors = {
  corrosion: baseColors.blue,
};

export const getColorLuma = (colorString) => {
  let rgb = parseInt(colorString, 16); // convert rrggbb to decimal
  let r = (rgb >> 16) & 0xff; // extract red
  let g = (rgb >> 8) & 0xff; // extract green
  let b = (rgb >> 0) & 0xff; // extract blue
  let luma = r * 0.2126 + g * 0.7152 + b * 0.0722; // per ITU-R BT.709 // 0 - 255
  return luma;
};

export const isColorDark = (colorString, threshold = 120) => {
  return getColorLuma(colorString) < threshold;
};

export const isColorLight = (colorString, threshold = 120) => {
  return getColorLuma(colorString) > threshold;
};

export const stringToColor = string => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    value = ('00' + value.toString(16));
    color += value.substring(value.length - 2);
  }
  return color;
};

export default {
  baseColors,
  getColorLuma,
  isColorDark,
  isColorLight,
  stringToColor,
};
