

export default {
  name: 'App',
  data() {
    return {
      showAddTask: false,
      asideEnabled: true,
    }
  },
  methods: {
    toggleAddTask() {
      this.showAddTask = !this.showAddTask
    },
  },
}
